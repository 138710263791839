import React, { useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  CardContent,
  CardActions,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  CardHeader,
} from "@material-ui/core";
import {
  Translate,
  TranslateFunction,
  LocalizeContextProps,
  withLocalize,
} from "react-localize-redux";

import { SimpleInput } from "./SimpleInput";
import { SupportedCountyCodes } from "../utils/supportedCountries";
import { useRegisterContext } from "../Providers/registerProvider";
import { useForm } from "react-hook-form";
import {
  register as registration,
  registerExternal as registrationExternal,
  validateAdacNumber,
} from "../utils/accountAPI";
import { useHistory } from "react-router-dom";
import { SupportedLanguageCodes } from "../utils/supportedLanguages";
import { RegistrationErrorResponse } from "../interfaces/ErrorResponses";
import { postCodeRegex, phoneNumberRegex } from "../utils/regexp";
import {
  getLinkToCookiesPolicy,
  getLinkToPrivacyPolicy,
} from "../utils/linkToDocs";
import { BwtCountrySelection } from "./BwtCountrySelection";
import { useUrlQuery } from "../utils/useUrlQuery";
import { BwtButton, BwtButtons } from "./BwtButton";
import { BwtLanguageSelection } from "./BwtLanguageSelection";
import QuestionMark from "../resources/HelpCircle.svg";
import { Overlay } from "./Overlay";
import { GenderSelection } from "./GenderSelection";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loginBtn: {
      marginTop: theme.spacing(1),
      flexGrow: 1,
      borderRadius: "50px",
      height: "50px",
    },
    cardContent: {
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(40),
      },
    },
    formControl: {
      width: "20vw",
      minWidth: "200px",
      marginBottom: "15px",
      height: "50px",
      maxWidth: "320px",
      display: "flex",
    },
    termAndConditions: {
      marginBottom: "15px",
      fontSize: "14px",
      lineHeight: "1.29",
      letterSpacing: "0.35px",
      width: theme.spacing(40),
      "& a": {
        color: "var(--col-navy-500)",
      },
      [theme.breakpoints.only("xs")]: {
        width: "100%",
      },
    },
    checkBoxRoot: {
      alignSelf: "start",
      color: "var(--col-navy-500)",
    },
    checkboxLabel: {
      width: theme.spacing(40),
      marginBottom: "15px",
      [theme.breakpoints.only("xs")]: {
        width: "100%",
      },
    },
    radioRoot: {
      color: "var(--col-navy-500)",
    },
    radioRootError: {
      color: "var(--col-accent-magnesium-500)",
    },
    errorLabel: {
      color: "var(--col-accent-magnesium-500)",
      fontSize: "12px",
      display: "flex",
    },
    header: {
      fontSize: "18px",
      fontWeight: "bold",
      lineHeight: "1.67",
      color: "var(--col-navy-500)",
    },
    adacFieldRoot: {
      display: "flex",
      //2px account for the 1px border used on simple input
      width: "calc(100% + 2px)",
      position: "relative",
    },
    adacQuestionMark: {
      top: "8px",
      right: "0",
      position: "absolute",
      backgroundImage: `url(${QuestionMark})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "25px",
      width: "25px",
      margin: "6px",
    },
  })
);

const RegisterDetailStep: React.FC<LocalizeContextProps> = ({
  activeLanguage,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const { state } = useRegisterContext();
  const isExternal = useUrlQuery().get("external") === "true";
  const [isButtonDisabled, setSubmitDisabled] = useState(false);
  const [hideAdacNumber, setHideAdacNumber] = useState(true);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const {
    handleSubmit,
    control,
    errors,
    register,
    setValue,
    clearError,
    setError,
    getValues,
    watch,
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    if ((!state.email || !state.password) && !isExternal) {
      // if user lands on this page and the state is missing email or password redirect to /register to fill that in. Ignore for external registration
      history.push("/register");
      return;
    }
    register({ name: "gender" }, { required: true });
    register({ name: "contactMe" }, { required: false });
  }, [register, history, state, isExternal]);

  const onCountryChange = (countryCode: string) => {
    if (control.fieldsRef.current.postcode) {
      control.fieldsRef.current.postcode.pattern =
        getPostcodeRegex(countryCode);
    }
    control.triggerValidation("postcode");
    // ADAC number has been removed from sign up, so only concern should be hiding the field
    if (hideAdacNumber === false) {
      setHideAdacNumber(true);
    }
    return countryCode;
  };
  const handleGender = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    clearError("gender");
    setValue("gender", value);
  };
  const handleLogin = (
    data: Record<string, any>,
    translate: TranslateFunction
  ) => {
    setSubmitDisabled(true);
    const adacNumber =
      data["adacNumber"] === "" || !data["adacNumber"]
        ? null
        : data["adacNumber"].padStart(9, "0");
    const externalRegistration = () => {
      registrationExternal({
        AcceptedTermsOfService: true,
        AcceptedMarketingUsage: data["contactMe"],
        ReturnUrl:
          state.returnUrl || localStorage.getItem("returnUrl") || "/profile",
        Gender: data["gender"],
        LastName: data["lastName"],
        FirstName: data["firstName"],
        PhoneNumber: data["phone"],
        PostalCode: data["postcode"],
        Street: data["address"],
        City: data["location"],
        CountryCode: data["country"],
        PreferredLanguage: data["prefLanguage"],
        AdacNumber: adacNumber,
      })
        .then(
          (res: any) => {
            localStorage.removeItem("returnUrl");
            window.location.href = res?.data?.redirectUrl || "/profile";
          },
          (rej: any) => {
            if (rej.response.data.errors) {
              handleErrorResponse(rej.response.data, translate);
              return;
            }
            history.push("/error?code=RDS-004");
            return;
          }
        )
        .catch((error: any) => {
          console.error(error);
          history.push("/error?code=RDS-005");
        });
    };

    const regularRegistration = () => {
      registration({
        Email: state.email,
        Password: state.password,
        AcceptedTermsOfService: true,
        AcceptedMarketingUsage: data["contactMe"],
        ReturnUrl:
          state.returnUrl || localStorage.getItem("returnUrl") || "/profile",
        Gender: data["gender"],
        LastName: data["lastName"],
        FirstName: data["firstName"],
        PhoneNumber: data["phone"],
        PostalCode: data["postcode"],
        Street: data["address"],
        City: data["location"],
        CountryCode: data["country"],
        PreferredLanguage: data["prefLanguage"],
        AdacNumber: adacNumber,
      })
        .then(
          (res: any) => {
            if (res?.data?.errorCode === "1002") {
              history.push("/error?code=RDS-001");
              return;
            }
            if (res?.data?.errorCode === "1003") {
              history.push("/error?code=RDS-003");
              return;
            }
            localStorage.removeItem("returnUrl");
            window.location.href = res?.data?.redirectUrl || "/profile";
          },
          (rej: any) => {
            handleErrorResponse(rej.response.data, translate);
          }
        )
        .catch(() => {
          history.push("/error?code=RDS-002");
        });
    };

    if (adacNumber !== null) {
      validateAdacNumber(adacNumber, "").then((resp: any) => {
        if (resp.data === true) {
          if (isExternal) {
            externalRegistration();
          } else {
            regularRegistration();
          }
        } else {
          const error =
            resp.data === "NumberAlreadyUsed"
              ? translate("validation.numberAlreadyUsed").toString()
              : translate("validation.invalidAdacAccountNumber").toString();
          setSubmitDisabled(false);
          setError("adacNumber", "unknown", error);
        }
      });
    } else {
      if (isExternal) {
        externalRegistration();
      } else {
        regularRegistration();
      }
    }
  };

  const handleErrorResponse = (
    error: RegistrationErrorResponse,
    translate: TranslateFunction
  ) => {
    const errorMsg = translate("register.notValidInput").toString();
    if (error.errors?.PhoneNumber)
      setError("phone", "serverSideError", errorMsg);
    if (error.errors?.CountryCode)
      setError("country", "serverSideError", errorMsg);
    if (error.errors?.FirstName)
      setError("firstName", "serverSideError", errorMsg);
    if (error.errors?.LastName)
      setError("lastName", "serverSideError", errorMsg);
    if (error.errors?.Gender) setError("gender", "serverSideError", errorMsg);
    if (error.errors?.City) setError("location", "serverSideError", errorMsg);
    if (error.errors?.PostalCode)
      setError("postcode", "serverSideError", errorMsg);
    if (error.errors?.PreferredLanguage)
      setError("prefLanguage", "serverSideError", errorMsg);
    if (error.errors?.Street) setError("address", "serverSideError", errorMsg);
  };
  const getPostcodeRegex = (country?: string) => {
    if (country) {
      return postCodeRegex.find((val) => val.countryCode === country)?.regExp;
    }
  };

  return (
    <React.Fragment>
      <Translate>
        {({ translate }) => (
          <form
            autoComplete="on"
            onSubmit={handleSubmit((data) => handleLogin(data, translate))}
          >
            <CardHeader
              title={translate("register.welcomeDetail")}
              classes={{ title: classes.header }}
            />
            <CardContent className={classes.cardContent}>
              <div>
                <GenderSelection
                    handleGenderChange={handleGender}
                    errors={errors}
                    value={getValues()["gender"]}
                />
                {!isExternal && (
                  <>
                    <SimpleInput
                      id={"firstName"}
                      type="text"
                      label={translate("register.firstName") as string}
                      control={control}
                      errors={errors}
                      required={true}
                      autocomplete="given-name"
                    ></SimpleInput>
                    <SimpleInput
                      id={"lastName"}
                      type="text"
                      label={translate("register.lastName") as string}
                      control={control}
                      errors={errors}
                      required={true}
                      autocomplete="family-name"
                    ></SimpleInput>
                  </>
                )}
                <SimpleInput
                  id={"phone"}
                  type="text"
                  label={translate("register.phone") as string}
                  control={control}
                  errors={errors}
                  regexValidation={phoneNumberRegex}
                  autocomplete="tel"
                ></SimpleInput>
                <SimpleInput
                  id={"address"}
                  type="text"
                  label={translate("register.address") as string}
                  control={control}
                  errors={errors}
                  required={true}
                  autocomplete={"street-address"}
                ></SimpleInput>
                <SimpleInput
                  id={"postcode"}
                  type="text"
                  label={translate("register.postcode") as string}
                  control={control}
                  errors={errors}
                  required={true}
                  regexValidation={getPostcodeRegex(getValues()["country"])}
                  onchange={([selected]) => {
                    selected.target.value = (
                      selected.target.value as string
                    ).toUpperCase();
                    return selected;
                  }}
                  autocomplete={"postal-code"}
                ></SimpleInput>
                <SimpleInput
                  id={"location"}
                  type="text"
                  label={translate("register.location") as string}
                  control={control}
                  errors={errors}
                  required={true}
                  autocomplete="address-level2"
                ></SimpleInput>
                <BwtCountrySelection
                  errors={errors}
                  control={control}
                  onChange={onCountryChange}
                  selections={SupportedCountyCodes}
                  controllerName="country"
                  label={translate("register.country").toString()}
                ></BwtCountrySelection>
                <BwtLanguageSelection
                  errors={errors}
                  control={control}
                  selections={SupportedLanguageCodes}
                  controllerName="prefLanguage"
                  label={translate("register.prefLanguage").toString()}
                ></BwtLanguageSelection>
                {!hideAdacNumber && (
                  <div className={classes.adacFieldRoot}>
                    <SimpleInput
                      id={"adacNumber"}
                      type="text"
                      label={translate("register.adacNumber") as string}
                      regexValidation={RegExp("^[0-9]{0,9}$")}
                      control={control}
                      errors={errors}
                      short={true}
                    />
                    <div
                      className={classes.adacQuestionMark}
                      onClick={() => setOverlayOpen(true)}
                    />
                  </div>
                )}
                <Overlay
                  isOpen={overlayOpen}
                  close={() => setOverlayOpen(false)}
                />
                <div className={classes.termAndConditions}>
                  <span>
                    {translate(
                      "register.privacyPolicyAgreement",
                      {
                        privacyPolicyLink: getLinkToPrivacyPolicy(
                          activeLanguage.code
                        ),
                        cookiesLink: getLinkToCookiesPolicy(
                          activeLanguage.code
                        ),
                      },
                      { renderInnerHtml: true }
                    )}
                  </span>
                </div>
                <FormControlLabel
                  className={classes.checkboxLabel}
                  control={
                    <Checkbox
                      classes={{ root: classes.checkBoxRoot }}
                      color="secondary"
                      checked={watch("contactMe")}
                      onChange={(e) => {
                        setValue("contactMe", e.target.checked);
                      }}
                      name="contactMe"
                    />
                  }
                  label={translate(
                    "register.contactEmail",
                    {},
                    { renderInnerHtml: true }
                  )}
                />
              </div>
            </CardContent>
            <CardActions>
              <BwtButton
                type="submit"
                disabled={isButtonDisabled}
                label={translate("register.createAccount").toString()}
                styleType={BwtButtons.pink}
              ></BwtButton>
            </CardActions>
          </form>
        )}
      </Translate>
    </React.Fragment>
  );
};
export default withLocalize(RegisterDetailStep);
